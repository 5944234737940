.linear-gradient-section {
  position: relative;
  padding-right: var(--default-section-spacing-X);
  padding-left: var(--default-section-spacing-X);
  padding-top: var(--default-section-spacing-Y);
  padding-bottom: var(--default-section-spacing-Y);

  .background-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    background-size: cover;
    background-repeat: repeat;
    background-position: center center;
  }
}
