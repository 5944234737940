@import '../../styles/media_queries.scss';

body,
html {
  font-family: 'Open Sans', sans-serif;
}

h1 {
  font-size: 36px;
  font-weight: 800;
  line-height: 115%;

  @include tablet {
    font-size: 44px;
  }

  @include desktop {
    font-size: 52px;
  }
}

h2 {
  font-size: 36px;
  font-weight: bold;

  @include desktop {
    font-size: 44px;
  }
}

h3 {
  font-size: 22px;
  font-weight: bold;

  @include tablet {
    font-size: 28px;
  }

  @include desktop {
    font-size: 32px;
  }
}

h4 {
  font-size: 24px;
  font-weight: bold;

  @include desktop {
    font-size: 28px;
  }
}

h5 {
  font-size: 18px;
  font-weight: bold;

  @include desktop {
    font-size: 20px;
  }
}

p {
  font-size: 14px;
  line-height: 155%;

  @include desktop {
    font-size: 16px;
  }
}
