aside {
  position: fixed;
  z-index: 9;

  button.is-white {
    position: relative;
    top: var(--default-section-spacing-Y);
    left: var(--default-section-spacing-X);
    z-index: 99;
    @apply bg-blue;
  }

  .sidebar-menu {
    position: fixed;
    z-index: 9;
    left: 0;
    top: 0;
    right: auto;
    bottom: 0;
    width: 100vw;

    &.is-open {
      display: flex;
      box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.05);
      background-color: #171453;
    }

    &.is-closed {
      display: none;
    }

    .sidebar-list {
      width: calc(100% - var(--default-section-spacing-X) - 20px);
      margin-top: calc(100px + var(--default-section-spacing-Y));
      padding-left: var(--default-section-spacing-X);
      padding-right: var(--default-section-spacing-X + 20px);
      li {
        cursor: pointer;
      }
      .sidebar-link {
        padding: 10px 20px;
        @apply bg-blue;
        @apply text-white;
        margin-bottom: 3px;
        font-size: 16px;
        text-decoration: none;
        display: block;
        transition: background-color 0.5s ease-in-out;

        &:hover {
          @apply bg-green;
        }
      }
    }
  }
}
