textarea {
  background-color: #ffffff;
  padding: 16px 20px 16px 20px;
  width: 100%;
  resize: none;
  &:focus {
    outline: none;
    border-bottom: 2px solid #ea3368;
  }
}

.kwes-form textarea.kw-border-success {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #23d160;
}

.kwes-form textarea.kw-border-error {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #ff3852;
}
