@import '../../../styles/media_queries.scss';
.card {
  padding: 36px 30px;
  background-color: #fff;
  box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.05);

  svg {
    margin: 0 auto;
  }

  .card-content.has-image {
    h4 {
      text-align: left;
      padding-left: 28px;
      padding-right: 28px;
    }
    p {
      text-align: left;
      padding-left: 28px;
      padding-right: 28px;
    }
    button {
      float: right;
      margin-right: 20px;
      margin-top: 24px !important;
      margin-bottom: 28px !important;
    }
  }
}
