@import './../../styles/media_queries.scss';

[class^='number-slide'],
[class*=' number-slide'] {
  background: grey;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 50px;
  color: #fff;
  font-weight: 500;
  height: 300px;
  max-height: 100vh;
}

.keen-slider__slide {
  background-color: #ffffff;
  padding: 30px;
  @include desktop {
    padding: 60px;
  }

  p {
    &::after {
      content: '';
      height: 2px;
      background-color: #e6e6e6;
      display: block;
      margin-top: 40px;
    }
  }
}

.navigation-wrapper {
  position: relative;
}

.arrow {
  width: 35px;
  height: 35px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  fill: #fff;
  cursor: pointer;

  &:hover {
    rect {
      @apply fill-green;
    }
  }
}

.arrow--left {
  left: 5px;
}

.arrow--right {
  left: auto;
  right: 5px;
}

.arrow--disabled {
  fill: rgba(255, 255, 255, 0.5);
}
