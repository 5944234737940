@import '../../styles/media_queries.scss';

.kodiraonica-container {
  margin: 0 auto;
  position: relative;
  z-index: 1;

  @include large-desktop {
    width: 1200px;
  }
}
