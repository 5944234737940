.modul-list {
  list-style-type: none;
  li {
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 157%;
    position: relative;
    padding-left: 15px;
    margin-bottom: 10px;

    &::after {
      content: '';
      display: block;
      position: absolute;
      top: 50%;
      left: 0px;
      transform: translateY(-50%);
      width: 10px;
      height: 10px;
      background-image: url('./../../../images/bullet.png');
      background-size: contain;
      background-repeat: no-repeat;
    }
  }

  &.fokus {
    li {
      font-size: 16px;
      font-weight: normal;
      margin-bottom: 5px;
    }
  }
}
