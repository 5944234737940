.global__section {
  padding-top: var(--default-section-spacing-Y);
  padding-bottom: var(--default-section-spacing-Y);
  padding-left: var(--default-section-spacing-X);
  padding-right: var(--default-section-spacing-X);

  &.pt-0 {
    padding-top: 0;
  }

  &.pb-0 {
    padding-bottom: 0;
  }
}
