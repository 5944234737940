footer {
  padding: 60px 0;
  color: white;

  a {
    display: flex;
    margin-right: 10px;
    position: relative;

    &:hover {
      top: 1px;
      @apply text-green;
    }
  }
}

main {
  min-height: 100%;
  display: grid;
  grid-template-rows: auto 1fr auto;
  grid-template-columns: 100%;
}
