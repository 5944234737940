@import './../../styles/media_queries.scss';

header {
  display: flex;
  align-items: center;

  position: relative;
  overflow: hidden;
  padding-top: 100px;
  padding-bottom: 70px;

  &.is-homepage {
    padding-top: 100px;
    padding-bottom: 50px;

    @include desktop {
      padding-top: 170px;
      padding-bottom: 150px;
    }
  }

  &.has-single-column {
    padding-top: 100px;
    padding-bottom: 50px;
    justify-content: center;

    @include desktop {
      padding-top: 150px;
    }
  }
}
