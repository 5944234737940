.dropdown {
  position: relative;
  padding: 10px 20px;
  font-size: 16px;
  transition: background-color 0.5s ease-in-out;
  max-height: 44px;
  &:hover,
  &:active,
  &:focus {
    @apply bg-green;
  }
  &__content {
    position: absolute;
    display: none;
    width: 320px;
    padding: 15px 20px;
    background-color: darken(#231e7c, 10%);
    margin-top: 10px;
    margin-left: -20px;

    &.is-open {
      display: block;
      ul {
        display: block;

        li a {
          text-decoration: none;
          position: relative;
          display: inline-block;
          &:after {
            content: '';
            opacity: 0;
            width: calc(100% - 40px);
            height: 2px;
            @apply bg-green;
            transition: width 0.3s;
            position: absolute;
            bottom: 0;
            left: 0px;
            right: 0px;
            margin: 0 auto;
            transition: opacity 0.5s ease-in-out;
          }

          &:hover:after {
            opacity: 1;
          }

          &:hover {
            background-color: transparent;
          }
        }
      }
    }

    &.is-closed {
      display: none;
    }
  }
}
