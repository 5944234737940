@import './flexboxgrid.scss';
@import './styles/media_queries.scss';

* {
  box-sizing: border-box;
}

html,
body {
  overflow-x: hidden;
}

:root {
  --default-section-spacing-Y: 40px;
  --default-section-spacing-X: 20px;
  --button-max-width: 200px;
}

@include desktop {
  :root {
    --button-max-width: none;
  }
}

@include large-desktop {
  :root {
    --default-section-spacing-Y: 70px;
    --default-section-spacing-X: 0px;
  }
}

.svg-hover {
  &:hover {
    svg {
      path {
        @apply fill-green;
      }
    }
  }
}
