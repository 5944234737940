.bg-blue {
  h1,
  p {
    color: white;
  }
}

.blog-section {
  button.is-black {
    margin-top: 20px;
    float: right;
  }
}

.cta-section {
  button {
    width: 100%;
  }
}
