.input {
  width: 100%;
  padding: 16px 20px;
  background-color: #ffffff;

  &:focus {
    outline: none;
    border-bottom: 2px solid #e3386a;
  }
}
