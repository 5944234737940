$mobile-width: 480px;
$tablet-width: 768px;
$desktop-width: 1024px;
$large-desktop-width: 1400px;
$extra-large-desktop-width: 1600px;

@mixin mobile {
  @media (min-width: #{$mobile-width}) and (max-width: #{$tablet-width - 1px}) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: #{$tablet-width}) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: #{$desktop-width}) {
    @content;
  }
}

@mixin large-desktop {
  @media (min-width: #{$large-desktop-width}) {
    @content;
  }
}

@mixin extra-large-desktop {
  @media (min-width: #{$extra-large-desktop-width}) {
    @content;
  }
}
