.accordion {
  border: none;
  .item {
    box-shadow: 4px 4px 15px 0px rgba(0, 0, 0, 0.05);
    border: none;
    margin-bottom: 12px;
  }

  .button {
    padding: 20px 35px;
    font-weight: bold;
    font-size: 18px;
  }

  .panel {
    border-top: 2px solid #f0f0f0;
    padding: 20px 35px;
  }

  .chevron {
    &:hover {
      circle {
        @apply fill-green;
      }
    }
  }
}
