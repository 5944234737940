.headroom-wrapper {
  z-index: 9;
  .headroom--unpinned {
    nav {
      background-color: darken(#231e7c, 10%);
    }

    button,
    a.is-pink {
      @apply bg-pink;
    }
  }

  .headroom--pinned {
    nav {
      background-color: darken(#231e7c, 10%);
    }

    button,
    a.is-pink {
      @apply bg-pink;
    }
  }

  nav {
    background-color: transparent;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    z-index: 9;
    color: white;
    padding: 40px 0;
    transition: background-color 0.5s ease-in-out;

    ul {
      display: flex;
      li {
        cursor: pointer;
      }
      a {
        padding: 10px 20px;
        font-size: 16px;
        text-decoration: none;
        display: block;
        transition: background-color 0.5s ease-in-out;

        &:hover {
          @apply bg-green;
        }
      }
    }
  }
}
