@import './../../../styles/media_queries.scss';

.as-button,
button {
  display: inline-flex;
  border: none;
  padding: 12px 20px;
  font-size: 14px;
  font-weight: 700;
  color: #fff;
  transition: background-color 0.3s ease-in-out, border-color 0.3s ease-in-out;

  @include tablet {
    font-size: 16px;
    padding: 12px 30px;
  }

  &.has-max-width {
    max-width: var(--button-max-width);
  }

  &.text-black {
    color: #000;
  }

  &.is-blue {
    @apply bg-blue;
    &:hover {
      @apply bg-green;
    }
  }

  &.is-pink {
    @apply bg-pink;
    &:hover {
      @apply bg-green;
    }
  }

  &.is-white {
    background-color: transparent;
    border: 2px solid #ffffff;
    color: #ffffff;
    padding: 10.5px 20px;

    &:hover {
      @apply border-green;
      @apply bg-green;
    }

    @include tablet {
      font-size: 16px;
      padding: 10.5px 30px;
    }
  }

  &.is-black {
    background-color: transparent;
    border: 2px solid #000000;
    color: #000000;
    padding: 10.5px 20px;

    @include tablet {
      font-size: 16px;
      padding: 10.5px 30px;
    }
    &:hover {
      @apply border-green;
      @apply bg-green;
    }
  }

  &.is-large {
    padding: 16px 60px;
  }
}

.kwes-form input.kw-border-success {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #23d160;
}

.kwes-form input.kw-border-error {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid #ff3852;
}
